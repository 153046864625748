<template>
  <div class="main-page">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'main-page'
}
</script>

<style lang="scss" scoped>

</style>
