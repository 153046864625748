<template>
    <div @click="$emit('click')" class="card"
         :class="[value.type, { 'compact': compact, 'detailed': detailed, 'draft': draft, 'back': back }]"
         :style="{zoom: draft? 1: zoom}">
        <template v-if="!back">
            <template v-if="!draft">
                <div class="image-container" :class="'source-' + value.imageSource">
                    <b-img v-if="value.image" class="image" :src="value.image"></b-img>
                </div>
                <b-img class="overlay" :src="require('../assets/card/card-frame-recto.png')"></b-img>
            </template>
            <div class="content" :class="{ 'with-bg': value.text }">
                <div class="card-text" v-if="value.text">{{value.text}}</div>
            </div>
            <div class="footer">
                <h4 class="name">{{ value.name }}</h4>
            </div>
        </template>
        <template v-else>
            <template v-if="!draft">
                <b-img class="overlay" :src="require('../assets/card/card-frame-verso.png')"></b-img>
            </template>

            <div class="category">{{ value.type }}</div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'game-card',
    props: {
        value: {
            type: Object,
            required: true
        },
        detailed: {
            type: Boolean,
            default: false
        },
        draft: {
            type: Boolean,
            default: false
        },
        compact: {
            type: Boolean,
            default: false
        },
        back: {
            type: Boolean,
            default: false
        },
        zoom: {
            type: Number,
            default: 1
        }
    },
    computed: {
        showPrice() {
            return this.value.cost.price > 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    margin: 2px;
    display: inline-block;
    position: relative;
    width: 750px;
    height: 1050px;
    font-size: 28pt;
    border-radius: 0;
    border: none;
    padding: 590px 12px 12px;
    background: none;
    vertical-align: top;
    overflow: hidden;
    font-family: 'Medieval Sharp', serif;

    &:not(.detailed) {
        user-select: none;

        &:hover {
            filter: drop-shadow(0px 0px 5px orange);
            cursor: pointer;
            z-index: 1;
        }
    }

    &.detailed {
        margin: 0 auto;
    }

    .header {
        position: absolute;
        z-index: 2;
        top: 12px;
        left: 12px;
        width: calc(100% - 24px);
        height: 90px;
        text-align: center;
        padding: 21px;

        background: url('../assets/card/title.png') no-repeat center center;
        background-size: 100%;
        background-blend-mode: overlay;

    }

    .image-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-color: #ddd;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.source-web {
            top: 40px;
            left: 40px;
            right: 40px;
            bottom: 165px;
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 165px;
        z-index: 0;
        text-align: center;

        &.with-bg {
            background: url('../assets/card/card-text-only.png') no-repeat center center;
            background-size: 100%;
            //background-blend-mode: overlay;
        }

        .card-text {
            padding: 40px;
            font-size: 35pt;
        }
    }

    .footer {
        position: absolute;
        bottom: 40px;
        right: 40px;
        left: 40px;
        z-index: 10;
        text-align: center;
        line-height: 84px;
        height: 100px;
        font-size: 23pt;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        .name {
            font-size: 33pt;
            font-weight: 700;
            margin: 0;
        }
    }

    .overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

.card.back {
    .overlay {
        z-index: 0;
    }

    .icon {
        position: absolute;
        z-index: 2;
        top: calc(50% - 128px);
        left: calc(50% - 128px);
        width: 256px;
        height: 256px;
        object-fit: contain;
    }

    .category {
        position: absolute;
        z-index: 2;
        top: calc(50% + 150px);
        width: 100%;
        text-align: center;
        font-size: 26pt;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
}

.card.compact {
    /*width: 50.5mm;
    height: 88.5mm;*/
    width: 596px;
    height: 1045px;

    .image-container {
        height: 455px;
    }
}

.card.draft {
    background: white;
    border: 1px solid #ddd;
    color: black;
    width: 88.5mm;
    height: 50.5mm;
    margin: 0;
    box-sizing: border-box;
    padding: 2px;
    font-size: 12pt;
    break-inside: avoid;

    .header {
        top: 0;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 10px 13px;
        background: none;

        h4 {
            font-size: 12pt;
            font-weight: 500;
        }
    }

    .content {
        .price {
            position: absolute;
            top: 0;
            right: 0;
            height: initial;
            line-height: inherit;
            padding: 5px 11px;
        }

        .description {
            display: block;
            height: auto;
            width: 100%;
            padding: 40px 11px;
            text-align: justify;
        }

        hr {
            margin: 10px 0;
            border-top: 1px dashed #ccc
        }
    }

    .footer {
        left: 13px;
        right: 0;
        bottom: 5px;
        font-size: 10pt;
        font-weight: 700;
        text-align: left;
        line-height: inherit;
    }

    .cards,
    .construction {
        width: initial;
        height: initial;
        padding: 0 13px;

        .number,
        .weight {
            font-size: 10pt;
            text-shadow: none;
        }
    }
}

.card.back.draft {
    color: #000;
    overflow: visible;

    .category {
        top: calc(50% - 20px);
        font-size: 16pt;
    }
}

@media print {
    .card.draft {
        border: none !important;
    }
}
</style>
