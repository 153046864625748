import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue } from 'bootstrap-vue'
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss'
import '@fortawesome/fontawesome-pro/scss/solid.scss'
// import VueHtml2Canvas from 'vue-html2canvas';


Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Vue.use(VueHtml2Canvas);

new Vue({
  render: h => h(App),
}).$mount('#app');
