<template>
    <div id="home" :class="{ 'draft': draft }">
        <div class="menu">
            <h1>Mariage Séb</h1>

            <div class="p-2" v-if="library">
                <h4>Options</h4>
                <b-form-group label="Zoom">
                    <b-form-spinbutton v-model.number="zoom" step="0.1" min="0.1" max="1"></b-form-spinbutton>
                </b-form-group>
                <b-form-group class="mt-2">
                    <b-checkbox switch v-model="back">Back Only</b-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-checkbox switch v-model="compact">Compact</b-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-checkbox switch v-model="draft">Draft mode</b-checkbox>
                </b-form-group>
                <b-form-group class="mt-2">
                    <b-select v-model="category">
                        <option value="all">All ({{library.cards.length}})</option>
                        <option v-for="category in library.categories" :key="'cat-' + category.id" :value="category.id">{{category.id}} ({{category.count}})</option>
                    </b-select>
                </b-form-group>

                <b-btn @click="saveAll" class="mt-2" :disabled="loading" variant="primary">Save all images</b-btn>
                <div class="f-500 p-3 text-center" v-if="loading">{{loading}}</div>
            </div>
            <div v-else>
                Loading...
                <i class="fas fa-truck-loading"></i>
            </div>
        </div>
        <main-page v-if="library">
            <template v-if="!savingMode">
                <card @click="selectCard(card)" v-for="card in cards" :key="card.id" :draft="draft" :compact="compact" :back="back" :value="card" :zoom="zoom"></card>

                <b-modal size="lg" centered v-model="modal" v-if="card" :hide-footer="true" body-class="text-center">
                    <template #modal-header>
                        <b-btn class="m-1" size="sm" variant="secondary" @click="printCard">Save <i class="fas fa-download ml-1"></i></b-btn>
                    </template>
                    <card ref="printableCard" :draft="draft" :value="card" :compact="compact" :back="back" :detailed="true"></card>
    <!--                <img :src="image">-->
                </b-modal>
            </template>
            <template v-else>
                <card ref="printableCard" :draft="draft" :value="card" :compact="compact" :back="back" :detailed="true"></card>
            </template>
        </main-page>
    </div>
</template>

<script>
    import {CardLibrary} from "../helpers/CardLibrary";
    import MainPage from "../components/main-page";
    import Card from "../components/game-card.vue";
    import download from "downloadjs";

    export default {
        name: "view-home",
        components: {Card, MainPage},
        data() {
            return {
                zoom: 0.3,
                modal: false,
                card: null,
                library: null,
                loading: null,
                image: null,
                back: false,
                draft: false,
                compact: false,
                savingMode: false,
                category: `all`
            }
        },
        mounted() {
            this.library = new CardLibrary();
            if (localStorage.getItem(`category`)) {
                this.category = localStorage.getItem(`category`);
            }
        },
        computed: {
            cards() {
                if (this.category === `all`) {
                    return this.library.cards;
                } else {
                    return this.library.cards.filter(c => {
                        return c.category === this.category;
                    });
                }
            }
        },
        methods: {
            selectCard(card) {
                this.card = card;
                this.modal = true;
            },
            async printCard() {
                const el = this.$refs.printableCard.$el;
                const options = {
                    type: 'dataURL',
                    backgroundColor: 'transparent',
                    width: 750,
                    height: 1050
                };
                this.image = await this.$html2canvas(el, options);
                download(this.image, '001' + ' ' + this.card.index + ' - ' + this.card.name + '.png');
            },
            async saveAll() {
                this.savingMode = true;

                for (let i = 0; i < this.cards.length; i++) {
                    this.card = this.cards[i];
                    this.loading = `${i} / ${this.cards.length}`;
                    await this.timeout(50);
                    await this.printCard();
                    await this.timeout(50);
                }
                this.loading = null;
                this.savingMode = false;
            },
            timeout(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        },
        watch: {
            category() {
                localStorage.setItem(`category`, this.category);
            }
        }
    }
</script>

<style lang="scss" scoped>
     #home {
         padding-left: 270px;

         .menu {
             position: fixed;
             top: 0;
             left: 0;
             width: 270px;
             height: 100%;
             flex: 1;
             background: #ddd;
             color: #333;
             padding: 10px;
             border-right: 1px solid white;

             ::v-deep .custom-control-label {
                 margin-left: 5px;
             }
         }

         &.draft {
             background: #ddd;

             .main-page {
                 background: #fff;
                 width: 216mm;
                 //height: 279mm;
                 padding: 13mm 18mm;
                 //overflow: hidden auto;
             }
         }
     }

    @media print {
        #home {
            padding: 0;

            .main-page {
                padding: 0 !important;
                overflow: visible;
            }
        }
        .menu {
            display: none;
        }
    }
</style>
