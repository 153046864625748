export class Card {
    constructor(data, index) {
        this.index = index;
        this.data = data;
        this.type = data.type;
        this.name = data.name;
        this.text = data.text;
        this.category = this.type.toLowerCase().trimStart().trimEnd();

        this.imageSource = `local`;
        if (data.image) {
            this.image = `/library/${data.image}.png`;
        } else if (data.image_link) {
            this.image = data.image_link;
            this.imageSource = `web`;
        } else {
            this.image = null;
        }

        this.id = `${this.index} ${this.name}`;
    }
}
